import axios from "axios"

const makeRequest = axios.create({
  baseURL: 'https://egmarkets-cms.herokuapp.com/api/',
  params: {
    pagination: {
      pageSize: 10
    },
    sort: 'createdAt:desc'
  },
  headers: {
    Authorization: `Bearer ${process.env.GATSBY_STRAPI_KEY}`
  }
})

const processData = (response) => {
  return response.data.data.map(datum => {
    return {
      id: datum.id,
      ...datum.attributes
    }
  })
}

export const getAllNotifications = () => {
  return Promise.all(
    [
      makeRequest('webinars', {
        params: {
          sort: 'date:desc',
        }
      }),
      makeRequest('financials'),
      makeRequest('economics'),
    ]
  ).then(data => data.map(processData)).then(data => ({
    webinars: data[0],
    financialMarkets: data[1],
    economicCalendar: data[2]
  }))
}