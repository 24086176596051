export const NOTIFICATION_CATEGORIES = {
  ECONOMIC_CALENDAR: "economic_calendar",
  WEBINARS: "webinars",
  FINANCIAL_MARKETS: "financial_markets"
}

export const LOCALSTORAGE_KEYS = {
  [NOTIFICATION_CATEGORIES.WEBINARS]: "lvWebinars",
  [NOTIFICATION_CATEGORIES.FINANCIAL_MARKETS]: "lvFinancialMarkets",
  [NOTIFICATION_CATEGORIES.ECONOMIC_CALENDAR]: "lvEconomicCalendar"
}

export const STATE_KEYS = {
  [NOTIFICATION_CATEGORIES.WEBINARS]: "webinars",
  [NOTIFICATION_CATEGORIES.FINANCIAL_MARKETS]: "financialMarkets",
  [NOTIFICATION_CATEGORIES.ECONOMIC_CALENDAR]: "economicCalendar"
}