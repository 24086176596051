import React from "react"
import { QueryClient, QueryClientProvider } from "react-query"

import "./src/style/tailwind.css"
import NotificationsProvider from "./src/context/notifications"

const queryClient = new QueryClient();

export const wrapRootElement = ({element}) => (
    <QueryClientProvider client={queryClient}>
      <NotificationsProvider>
        {element}
      </NotificationsProvider>
    </QueryClientProvider>
)