// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-pages-assets-single-index-js": () => import("./../../../src/components/pages/assets/single/index.js" /* webpackChunkName: "component---src-components-pages-assets-single-index-js" */),
  "component---src-components-pages-markets-single-index-js": () => import("./../../../src/components/pages/markets/single/index.js" /* webpackChunkName: "component---src-components-pages-markets-single-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-academy-guides-index-js": () => import("./../../../src/pages/academy/guides/index.js" /* webpackChunkName: "component---src-pages-academy-guides-index-js" */),
  "component---src-pages-academy-guides-training-guides-financial-markets-101-js": () => import("./../../../src/pages/academy/guides/training-guides/financial-markets-101.js" /* webpackChunkName: "component---src-pages-academy-guides-training-guides-financial-markets-101-js" */),
  "component---src-pages-academy-webinars-js": () => import("./../../../src/pages/academy/webinars.js" /* webpackChunkName: "component---src-pages-academy-webinars-js" */),
  "component---src-pages-academy-webinars-upcoming-js": () => import("./../../../src/pages/academy/webinars/upcoming.js" /* webpackChunkName: "component---src-pages-academy-webinars-upcoming-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-get-help-js": () => import("./../../../src/pages/get-help.js" /* webpackChunkName: "component---src-pages-get-help-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-from-masters-js": () => import("./../../../src/pages/learn-from-masters.js" /* webpackChunkName: "component---src-pages-learn-from-masters-js" */),
  "component---src-pages-markets-mt-4-js": () => import("./../../../src/pages/markets/mt4.js" /* webpackChunkName: "component---src-pages-markets-mt-4-js" */),
  "component---src-pages-partnerships-js": () => import("./../../../src/pages/partnerships.js" /* webpackChunkName: "component---src-pages-partnerships-js" */),
  "component---src-pages-platforms-copytrade-js": () => import("./../../../src/pages/platforms/copytrade.js" /* webpackChunkName: "component---src-pages-platforms-copytrade-js" */),
  "component---src-pages-platforms-eagletrade-js": () => import("./../../../src/pages/platforms/eagletrade.js" /* webpackChunkName: "component---src-pages-platforms-eagletrade-js" */),
  "component---src-pages-platforms-mt-4-js": () => import("./../../../src/pages/platforms/mt4.js" /* webpackChunkName: "component---src-pages-platforms-mt-4-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-trade-with-harry-js": () => import("./../../../src/pages/trade-with-harry.js" /* webpackChunkName: "component---src-pages-trade-with-harry-js" */)
}

