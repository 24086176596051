import React, { useState } from "react"
import { useQuery } from "react-query"
import { getAllNotifications } from "../api/notifications"
import isAfter from "date-fns/isAfter"
import { LOCALSTORAGE_KEYS, NOTIFICATION_CATEGORIES, STATE_KEYS } from "../components/layout/notifications/constants"

const initialState = {
  webinars: [],
  financialMarkets: [],
  economicCalendar: [],
  count: {
    webinars: 0,
    financialMarkets: 0,
    economicCalendar: 0
  },
  totalCount: 0
}

export const NotificationsContext = React.createContext({
  notifications: initialState,
  onOpenCategory: () => {}
});


const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState(initialState);

  const onOpenCategory = (key) => {
    const localStorageKey = LOCALSTORAGE_KEYS[key];
    localStorage.setItem(localStorageKey, new Date().toISOString());

    const stateKey = STATE_KEYS[key];


    setNotifications(prevState => {
      return {
        ...prevState,
        count: {
          ...prevState.count,
          [stateKey]: 0
        },
        totalCount: prevState.totalCount - prevState.count[stateKey]
      }
    })
  }

  useQuery( {
    queryFn: getAllNotifications,
    queryKey: 'getNotifications',
    staleTime: 20 * 1000 * 60, //Stale time is 20 minutes
    refetchInterval: 5 * 1000 * 60, //Refetch after 5 minutes
    retry: 0,
    onSuccess: (data) => {
      const localStorageKeys = {
        webinars: LOCALSTORAGE_KEYS[NOTIFICATION_CATEGORIES.WEBINARS],
        financialMarkets: LOCALSTORAGE_KEYS[NOTIFICATION_CATEGORIES.FINANCIAL_MARKETS],
        economicCalendar: LOCALSTORAGE_KEYS[NOTIFICATION_CATEGORIES.ECONOMIC_CALENDAR]
      }

      const count = {
        webinars: 0,
        financialMarkets: 0,
        economicCalendar: 0,
      }

      Object.entries(data).forEach(([key, group]) => {
        const localStorageData = localStorage.getItem(localStorageKeys[key]);

        if(!localStorageData){
          count[key] = group.length;
        }else{
          group.forEach(document => {
            if(isAfter(new Date(document.updatedAt), new Date(localStorageData))){
              count[key]++
            }
          })
        }
      })

      setNotifications({
        webinars: data.webinars,
        financialMarkets: data.financialMarkets,
        economicCalendar: data.economicCalendar,
        count,
        totalCount: Object.values(count).reduce((total, current) => total + current, 0)
      })
    }
  });

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        onOpenCategory
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}

export default NotificationsProvider